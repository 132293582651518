import { COLORS } from "../lib/constants"
// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

export const toggleCursorStyle = (modalState, colorMode) => {
  if (isBrowser) {
    const styleRoot = document.querySelector(":root")

    if (
      (colorMode === "light") & (modalState === "open") ||
      (colorMode === "dark") & (modalState === "close")
    ) {
      styleRoot.style.setProperty("--generalCursor", COLORS.generalCursor.dark)
      styleRoot.style.setProperty("--focusCursor", COLORS.focusCursor.dark)
      styleRoot.style.setProperty("--infoCursor", COLORS.infoCursor.dark)
    } else {
      styleRoot.style.setProperty("--generalCursor", COLORS.generalCursor.light)
      styleRoot.style.setProperty("--focusCursor", COLORS.focusCursor.light)
      styleRoot.style.setProperty("--infoCursor", COLORS.infoCursor.light)
    }
  }
}
