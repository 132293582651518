import { gsap } from "gsap"

export const showGrid = (thumbnailEls, carouselEl) => {
  const showGrid = gsap.timeline({
    defaults: { ease: "power2.in" },
  })

  showGrid
    .to(thumbnailEls, {
      opacity: 1,
      duration: 0.4,
      stagger: {
        amount: 0.3,
      },
    })
    .to(carouselEl, {
      opacity: 0,
      duration: 0,
    })
  return showGrid
}

export const hideGrid = (carouselEl, thumbnailEls) => {
  const hideGrid = gsap.timeline({
    defaults: { ease: "power2.in" },
  })

  hideGrid
    .to(carouselEl, {
      opacity: 1,
      duration: 0.1,
    })
    .to(thumbnailEls, {
      opacity: 0,
      duration: 0.4,
      stagger: {
        amount: 0.1,
      },
    })
  return hideGrid
}
