import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react"
import { navigate } from "gatsby"
// import { GatsbyImage } from "gatsby-plugin-image"
import useEffectSkipComponentMount from "../lib/useEffectSkipComponentMount"
import { showGrid, hideGrid } from "../lib/animations/gridView"

import Layout from "../components/layout"
import MobilePageNavigation from "../components/mobilePageNavigation"
import CloseX from "../icons/close-x"
import { toggleCursorStyle } from "../lib/toggleCursorStyle"
import { ThemeContext } from "./darkThemeContext"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Modal from "react-modal"

import SwiperCore, { A11y, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"

import get from "lodash/get"

import { gsap } from "gsap"
import { Flip } from "gsap/Flip"

import "lazysizes"
import "lazysizes/plugins/attrchange/ls.attrchange"

if (typeof window !== "undefined") {
  gsap.registerPlugin(Flip)
}

// install Swiper modules
SwiperCore.use([A11y, Autoplay])

const Root = ({ data, pageContext, locationData }) => {
  // -------------------------------------------------------- Grid state
  const [isGrid, setIsGrid] = useState(() => {
    const gridState = get(locationData, "state.isGrid", undefined)
    return gridState || false
  })

  // -------------------------------------------------------- Screen width

  const isTheScreenSmall = () => {
    if (typeof window !== "undefined") {
      return window.innerWidth < 768
    }
    return false
  }

  const [isMobile, setIsMobile] = useState(isTheScreenSmall)

  const updateDimensions = useCallback(() => {
    setIsMobile(window.innerWidth < 768)
  }, [])

  useEffect(() => {
    window.addEventListener("resize", updateDimensions)
    return () => window.removeEventListener("resize", updateDimensions)
  }, [updateDimensions])

  // -------------------------------------------------------- Cotentful data
  const allContentfulJournal = data.LargeImage
  // const smallImageData = data.SmallImage

  //-------------------------------------------------------Modal with title and caption
  const [modalIndex, setModalIndex] = useState(null)
  const { colorMode } = useContext(ThemeContext)

  const showImageDialogueOverlay = index => {
    toggleCursorStyle("open", colorMode)
    setModalIndex(index)
  }

  const handleCloseModal = () => {
    toggleCursorStyle("close", colorMode)
    setModalIndex(null)
  }

  // -----------------------------------------------------Animation refs
  const [isAnimationInProgress, setIsAnimationInProgress] = useState(false)
  // const [isFirstThumbnailLoaded, setIsFirstThumbnailLoaded] = useState(false)
  const gridContainerRef = useRef(null)
  const swiperContainerRef = useRef(null)
  const swiperRef = useRef(null)

  const toggleAnimationInProgress = useCallback(() => {
    setIsAnimationInProgress(!isAnimationInProgress)
  }, [isAnimationInProgress, setIsAnimationInProgress])

  const initialSlideIndex = () => {
    const inDirection = get(locationData, "state.to", undefined)
    if (inDirection === "left") return allContentfulJournal.nodes.length - 1
    return 0
  }
  const [currentSlideIndex, setCurrentSlideIndex] = useState(() =>
    initialSlideIndex()
  )

  // ----------------------------------------------------- On load

  useEffect(() => {
    const navInDirection = get(locationData, "state.to", undefined)

    if (isMobile) {
      let mobileImageReveal = gsap.timeline()
      mobileImageReveal
        .set(swiperContainerRef.current, { opacity: 1 })
        .set(".mobile-transition-cover", { left: "auto", right: 0 })
        .to(".mobile-transition-cover", {
          width: "0%",
          duration: 1.1,
          ease: "power2.in",
        })
    } else if (isGrid) {
      let revealGrid = gsap.timeline()
      revealGrid
        .add(moveCarousel)
        .set(gridContainerRef.current, {
          visibility: "visible",
        })
        .to(".gs-thumbnail", {
          opacity: 1,
          duration: 0.6,
          stagger: {
            amount: 0.3,
          },
        })
      // if there's no direction stored in navigate state
    } else if (!navInDirection) {
      // change to cool zoom out reveal
      gsap.to(swiperContainerRef.current, {
        opacity: 1,
        duration: 1,
        delay: 0.5,
      })
    } else if (navInDirection === "right") {
      swiperContainerRef.current.style.transform = "translateX(100vw)"
      swiperContainerRef.current.style.opacity = 1
      gsap.to(swiperContainerRef.current, { x: 0, duration: 0.8, delay: 0.6 })
    } else if (navInDirection === "left") {
      swiperContainerRef.current.style.transform = "translateX(-100vw)"
      swiperContainerRef.current.style.opacity = 1
      gsap.to(swiperContainerRef.current, { x: 0, duration: 0.8, delay: 0.6 })
    }

    window.history.replaceState({}, document.title)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // ----------------------------------------------------- Animations
  const smallToBig = useCallback(
    (oldCarouselState, currentCarouselEl, duration) => {
      const smallCarouselState = Flip.getState(currentCarouselEl)
      const bigToSmall = Flip.fit(smallCarouselState, oldCarouselState, {
        duration: duration,
        scale: true,
        ease: "power4.inOut",
      })
      return bigToSmall
    },
    []
  )

  const bigToSmall = useCallback(
    (currentSlideState, targetImageState, duration, currentSlideIndex) => {
      const bigToSmall = Flip.fit(currentSlideState, targetImageState, {
        duration: duration,
        fitChild: `#big-${currentSlideIndex} .fit-img-el`,
        scale: true,
        ease: "power4.inOut",
      })
      return bigToSmall
    },
    []
  )

  const nextPageSlideAnimation = useCallback(
    (path, state, direction) => {
      // // if an animation is in progress then don't start another
      // if (isAnimationInProgress) return
      const navigateNext = () => {
        navigate(path, state)
      }

      if (isMobile) {
        gsap
          .timeline()
          .set(".mobile-transition-cover", { left: 0, right: "auto" })
          .to(".mobile-transition-cover", {
            width: "100%",
            duration: 1,
            ease: "power2.in",
            onComplete: navigateNext,
          })
      } else if (isGrid) {
        gsap.to(".gs-thumbnail", {
          opacity: 0,
          duration: 0.4,
          ease: "power2.in",
          stagger: {
            amount: 0.3,
          },
          onComplete: navigateNext,
        })
      } else {
        gsap.fromTo(
          swiperContainerRef.current,
          {
            transform: "translate(0,0)",
          },
          {
            transform:
              direction === "right"
                ? "translate(-100vw,0vh)"
                : "translate(100vw,0vh)",
            duration: 0.6,
            ease: "power2.in",
            onComplete: navigateNext,
          }
        )
      }
    },
    [isGrid, isMobile]
  )

  const moveCarousel = useCallback(() => {
    let changeLayout = gsap.timeline()
    if (isGrid) {
      changeLayout.set(swiperContainerRef.current, {
        transform: "translate(0vh,100vh)",
      })
    } else {
      changeLayout.set(swiperContainerRef.current, {
        transform: "translate(0vh,0vh)",
      })
    }
    return changeLayout
  }, [isGrid, swiperContainerRef])

  const gridContainerHide = useCallback(() => {
    let hideGrid = gsap.timeline()
    hideGrid.set(gridContainerRef.current, {
      visibility: "hidden",
    })
    return hideGrid
  }, [gridContainerRef])

  // ----------------------------------------------------- Grid view state and state change
  const toggleIsGrid = () => {
    // if an animation is in progress then don't start another
    if (isAnimationInProgress) return
    setIsGrid(!isGrid)
    setIsAnimationInProgress(true)
  }

  const toGridAnimation = useCallback(() => {
    gridContainerRef.current.style.visibility = "visible"
    // get curent active slide element and it's thumbnail counter-part
    const currentSlideState = Flip.getState(
      `#big-${currentSlideIndex}  .fit-img-el`
    )
    const targetImageState = Flip.getState(
      `#thumb-${currentSlideIndex} picture .gs-thumbnail`
    )

    let toGrid = gsap.timeline({ onComplete: toggleAnimationInProgress })
    toGrid
      .add(
        bigToSmall(currentSlideState, targetImageState, 0.7, currentSlideIndex)
      )
      .add(showGrid(".gs-thumbnail", swiperContainerRef.current), "-=0.4")
      .add(
        smallToBig(
          currentSlideState,
          `#big-${currentSlideIndex} .fit-img-el`,
          0.1
        )
      )
      .add(moveCarousel)
  }, [
    swiperContainerRef,
    currentSlideIndex,
    bigToSmall,
    smallToBig,
    moveCarousel,
    toggleAnimationInProgress,
  ])

  const fromGridAnimation = useCallback(() => {
    // position carousel in the correct place again before getting element state
    swiperContainerRef.current.style.transform = "translate(0vw,0vh)"
    const currentSlideState = Flip.getState(
      `#big-${currentSlideIndex}  .fit-img-el`
    )
    const targetImageState = Flip.getState(
      `#thumb-${currentSlideIndex} picture .gs-thumbnail`
    )
    let fromGrid = gsap.timeline({
      onComplete: toggleAnimationInProgress,
    })

    fromGrid
      .add(
        bigToSmall(currentSlideState, targetImageState, 0.1, currentSlideIndex)
      )
      .add(hideGrid(swiperContainerRef.current, ".gs-thumbnail"))
      .add(
        smallToBig(
          currentSlideState,
          `#big-${currentSlideIndex} .fit-img-el`,
          0.7
        ),
        "-=0.1"
      )
      .add(gridContainerHide)
  }, [
    swiperContainerRef,
    currentSlideIndex,
    bigToSmall,
    smallToBig,
    gridContainerHide,
    toggleAnimationInProgress,
  ])

  useEffectSkipComponentMount(() => {
    if (swiperRef.current.swiper.autoplay.running) {
      swiperRef.current.swiper.autoplay.pause()
    }
    if (isGrid) {
      toGridAnimation()
    } else {
      fromGridAnimation()
    }
  }, [isGrid])

  // ----------------------------------------------------Swiper carousel & Navigation

  const morePageIndex = pageContext ? pageContext.currentPage : undefined
  const totalMorePages = pageContext ? pageContext.numMorePages : undefined

  const navInstruction = useCallback(
    direction => {
      // if an animation is in progress then don't start another
      if (isAnimationInProgress) return
      const state = {
        state: {
          isGrid: isGrid,
          to: direction,
        },
      }

      switch (direction) {
        case "left":
          if (
            (locationData.pathname.length > 1) &
            (swiperRef.current.swiper.isBeginning || isGrid) &
            (morePageIndex === 1)
          ) {
            nextPageSlideAnimation("/", state, direction)
          } else if (
            (locationData.pathname.length > 1) &
            (swiperRef.current.swiper.isBeginning || isGrid) &
            (morePageIndex > 1)
          ) {
            nextPageSlideAnimation(`/${morePageIndex - 1}/`, state, direction)
          } else {
            swiperRef.current.swiper.slidePrev()
          }
          break
        case "right":
          if (
            (locationData.pathname === "/") &
            (swiperRef.current.swiper.isEnd || isGrid)
          ) {
            nextPageSlideAnimation("/1/", state, direction)
          } else if (
            (locationData.pathname.length > 1) &
            (swiperRef.current.swiper.isEnd || isGrid) &
            (totalMorePages > morePageIndex)
          ) {
            nextPageSlideAnimation(`/${morePageIndex + 1}/`, state, direction)
          } else if (!isGrid & !swiperRef.current.swiper.isEnd) {
            swiperRef.current.swiper.slideNext()
          }
          break
        default:
          console.log("navInstruction has invalid params")
          return
      }
      return
    },
    [
      isGrid,
      locationData.pathname,
      morePageIndex,
      nextPageSlideAnimation,
      totalMorePages,
      isAnimationInProgress,
    ]
  )

  // set event listener for key press
  useEffect(() => {
    function onKeyPress(e) {
      if (e.code === "ArrowRight") {
        navInstruction("right")
      } else if (e.code === "ArrowLeft") {
        navInstruction("left")
      }
    }

    document.addEventListener("keydown", onKeyPress)

    return () => {
      document.removeEventListener("keydown", onKeyPress)
    }
  }, [isGrid, navInstruction])

  const goToSlide = index => {
    // if an animation is in progress then don't start another
    if (isAnimationInProgress) return
    setIsAnimationInProgress(true)
    // when thumbnail is clicked go to that slide in the carousel and transition from grid view
    swiperRef.current.swiper.slideTo(index, 0, false)
    setIsGrid(!isGrid)
  }

  ///-------------------------------------------------------------------------------

  return (
    <Layout
      toggleIsGrid={toggleIsGrid}
      navInstruction={navInstruction}
      isGrid={isGrid}
    >
      <div className="relative w-screen h-screen overflow-hidden background">
        <div ref={swiperContainerRef} className="relative opacity-0">
          <div className="mobile-transition-cover" />
          <Swiper
            ref={swiperRef}
            initialSlide={initialSlideIndex()}
            slidesPerView={1}
            centeredSlides={true}
            onSlideChange={() =>
              setCurrentSlideIndex(swiperRef.current.swiper.activeIndex)
            }
            autoplay={{
              delay: 10000,
              stopOnLastSlide: true,
            }}
            speed={1000}
            spaceBetween={0}
            simulateTouch={false}
            tag="section"
            wrapperTag="ul"
            direction={isMobile ? "vertical" : "horizontal"}
          >
            {allContentfulJournal.nodes.map((each, i) => (
              <SwiperSlide key={`slide-${each.id.slice(0, 8)}`} tag="li">
                <button
                  onClick={() => showImageDialogueOverlay(i)}
                  className="w-full h-full slide-image"
                  id={`big-${i}`}
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={each.image.fluid.srcSetWebp}
                      sizes={
                        each.image.file.details.image.width <
                        each.image.file.details.image.height
                          ? `(max-width:767px) 90vw, (max-width:1024px) 70vw, 35vw`
                          : `(max-width:767px) 90vw, 70vw`
                      }
                    />
                    <img
                      src={each.image.fluid.base64}
                      srcSet={each.image.fluid.srcSet}
                      alt={
                        each.image.description === ""
                          ? each.image.title
                          : each.image.description
                      }
                      className={
                        each.image.file.details.image.width <
                        each.image.file.details.image.height
                          ? "fit-img-el potrait-large-img"
                          : "fit-img-el landscape-large-img"
                      }
                      sizes={
                        each.image.file.details.image.width <
                        each.image.file.details.image.height
                          ? `(max-width:767px) 90vw, (max-width:1024px) 70vw, 35vw`
                          : `(max-width:767px) 90vw, 70vw`
                      }
                    />
                  </picture>
                </button>
                <Modal
                  isOpen={modalIndex === i}
                  ariaHideApp={false}
                  onRequestClose={handleCloseModal}
                  overlayClassName={`image-overlay-dialogue`}
                  className="contrast-text"
                  closeTimeoutMS={300}
                >
                  <div className="max-w-lg contrast-text">
                    <button
                      className="absolute top-0 right-0 mr-8 mt-7"
                      onClick={handleCloseModal}
                    >
                      <CloseX className="w-4 h-4" />
                    </button>
                    <h4 className="mb-1 text-base font-semibold">
                      {each.title}
                    </h4>
                    {each.body !== null
                      ? documentToReactComponents(JSON.parse(each.body.raw))
                      : ""}
                  </div>
                </Modal>
              </SwiperSlide>
            ))}
            )
          </Swiper>
        </div>
        <MobilePageNavigation
          locationData={locationData}
          pageContext={pageContext}
          allContentfulJournal={allContentfulJournal}
          currentSlideIndex={currentSlideIndex}
          navInstruction={navInstruction}
        />

        <section
          className={`grid invisible h-screen w-screen absolute inset-0 md:grid-cols-3 md:grid-rows-5 lg:grid-cols-5 lg:grid-rows-3 pt-20 pb-8 lg:px-8`}
          ref={gridContainerRef}
        >
          {allContentfulJournal.nodes.map((each, i) => (
            <button
              onClick={() => goToSlide(i)}
              key={`thumbnail-${each.id.slice(0, 8)}`}
              className="p-3 lg:p-6"
              id={`thumb-${i}`}
            >
              <picture>
                <source
                  type="image/webp"
                  data-srcset={each.image.fluid.srcSetWebp}
                  data-sizes="auto"
                />
                <source
                  data-srcset={each.image.fluid.srcSet}
                  data-sizes="auto"
                />
                <img
                  src={each.image.fluid.base64}
                  alt={
                    each.image.description === ""
                      ? each.image.title
                      : each.image.description
                  }
                  className={
                    each.image.file.details.image.width <
                    each.image.file.details.image.height
                      ? "lazyload opacity-0 gs-thumbnail portrait-grid-image"
                      : "lazyload opacity-0 gs-thumbnail landscape-grid-image"
                  }
                  data-sizes="auto"
                />
              </picture>
            </button>
          ))}
        </section>
      </div>
    </Layout>
  )
}
export default Root
