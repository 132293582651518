import React from "react"

const CloseX = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
      style={{ marginTop: "2px" }}
    >
      <title>close</title>

      <rect
        width="22"
        height="4"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 1 3.82843)"
      />
      <rect
        width="22"
        height="4"
        transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 3.63605 19.1924)"
      />
    </svg>
  )
}
export default CloseX
