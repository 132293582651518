import { gsap } from "gsap"

export const hideButton = button =>
  new gsap.timeline()
    .to(button, {
      opacity: 0,
      ease: "power2.in",
      duration: 0.5,
    })
    .to(button, {
      visibility: "hidden",
      zIndex: 0,
      duration: 0,
    })

export const showButton = button =>
  new gsap.timeline()
    .to(button, {
      visibility: "visible",
      zIndex: 11,
      duration: 0,
    })
    .to(button, {
      opacity: 1,
      ease: "power2.in",
      duration: 1,
    })
