import React, { useState, useEffect, useCallback, useRef } from "react"
import TriangleLeft from "../icons/triangleLeft"
import TriangleRight from "../icons/triangleRight"
import { hideButton, showButton } from "../lib/animations/toggleButton"

const MobilePageNavigation = ({
  locationData,
  pageContext,
  allContentfulJournal,
  currentSlideIndex,
  navInstruction,
}) => {
  const maxSlideIndex = allContentfulJournal.nodes.length - 1
  const morePageIndex = pageContext ? pageContext.currentPage : undefined
  const totalMorePages = pageContext ? pageContext.numMorePages : undefined

  const nextButtonRef = useRef(null)
  const prevButtonRef = useRef(null)

  const [isViewingFirstSlide, setIsViewingFirstSlide] = useState(true)
  const [initialSlideIndex] = useState(currentSlideIndex)
  useEffect(() => {
    if (initialSlideIndex !== currentSlideIndex) {
      setIsViewingFirstSlide(false)
    }
  }, [currentSlideIndex, initialSlideIndex])

  const isNextPage = useCallback(() => {
    const isHomePage = locationData.pathname === "/"
    if (isHomePage & (allContentfulJournal.totalCount > 15)) return true
    if (!isHomePage) {
      if (morePageIndex < totalMorePages) return true
    }
    return false
  }, [locationData, allContentfulJournal, morePageIndex, totalMorePages])

  const isHomePage = useCallback(() => {
    return locationData.pathname === "/"
  }, [locationData])

  const [isNextButtonShown, setIsNextButtonShown] = useState(null)

  const [isPrevButtonShown, setIsPrevButtonShown] = useState(null)

  useEffect(() => {
    if (isNextPage() & (currentSlideIndex === maxSlideIndex)) {
      setIsNextButtonShown(true)
    } else if (!isHomePage() & (currentSlideIndex === 0)) {
      setIsPrevButtonShown(true)
    } else if (isPrevButtonShown) {
      setIsPrevButtonShown(false)
    } else if (isNextButtonShown) {
      setIsNextButtonShown(false)
    }
  }, [
    currentSlideIndex,
    isNextPage,
    isHomePage,
    maxSlideIndex,
    isPrevButtonShown,
    isNextButtonShown,
  ])

  useEffect(() => {
    if (isViewingFirstSlide) return
    if (isNextButtonShown) {
      showButton(nextButtonRef.current)
    } else if (isNextButtonShown === false) {
      hideButton(nextButtonRef.current)
    }
  }, [isNextButtonShown, isViewingFirstSlide])

  useEffect(() => {
    if (isViewingFirstSlide) return
    if (isPrevButtonShown) {
      showButton(prevButtonRef.current)
    } else if (isPrevButtonShown === false) {
      hideButton(prevButtonRef.current)
    }
  }, [isPrevButtonShown, isViewingFirstSlide])

  return (
    <div className="md:hidden">
      <button
        onClick={() => navInstruction("left")}
        className={`font-semibold prev-button text`}
        ref={prevButtonRef}
      >
        <TriangleLeft className="text" />
        &nbsp;back
      </button>
      <button
        onClick={() => navInstruction("right")}
        className={`font-semibold next-button text`}
        ref={nextButtonRef}
      >
        more&nbsp;
        <TriangleRight className="text" />
      </button>
    </div>
  )
}
export default MobilePageNavigation
